<script>
// Définir tinymce comme une variable globale pour ESLint
/* global tinymce */

import AkLabel from "@components/general/AkLabel";

export default {
  components: {AkLabel},
  inject: {'pSubmitted' : {default: null}, 'pDisabled' : {default: null}, 'pReadonly' : {default: null}},
  props: {
    label: {
      type: String,
      required: true,
    },
    rows: {
      type: Number,
      required: false,
      default: 3,
    },
    modelValue: {
      required: true
    },
    placeholder: {
      type: String,
      required: false
    },
    className: {
      type: String,
      required: false,
      default: "col-md-4 col-6"
    },
    inputType: {
      type: String,
      required: false,
      default: "text"
    },
    validator: {
      type: Object,
      required: false
    },
    submitted: {
      type: Boolean,
      required: false,
      default: undefined
    },
    disabled: {
      type: Boolean,
      required: false,
      default: undefined
    },
    readonly: {
      type: Boolean,
      required: false,
      default: undefined
    },
  },
  mounted() {
      if (typeof tinymce !== 'undefined') {
        tinymce.init({
          selector: 'textarea',
          menubar: false,
          statusbar: false,
          language: 'fr',
          plugins: 'advlist lists',
          toolbar: 'bold italic underline | bullist numlist',
          branding: false,
          setup: (editor) => {
            editor.on('change', () => {this.tinymceChanged(editor);});
          },
        });
      } else {
        console.error('TinyMCE n\'est pas chargé');
      }
  },
  methods: {
    tinymceChanged(editor) {
      this.$emit('update:modelValue', editor.getContent());
    },
  },
  computed: {
    currentValue: {
      get() {
        return this.modelValue;
      },
      set(val) {
        this.$emit('update:modelValue', val);
      },
    },
    getPlaceholder() {
      return this.placeholder ? this.placeholder : this.label;
    },
    alreadySubmitted() {
      if (this.submitted !== undefined) return this.submitted;
      if (this.pSubmitted !== undefined) return this.pSubmitted;
      return false;
    },
    isDisabled() {
      if (this.disabled !== undefined) return this.disabled;
      if (this.pDisabled !== undefined) return this.pDisabled;
      return false;
    },
    isReadonly() {
      if (this.readonly !== undefined) return this.readonly;
      if (this.pReadonly !== undefined) return this.pReadonly;
      return false;
    },
    hasValidator() {
      return this.validator !== undefined;
    },
    isInvalid() {
      return this.hasValidator && this.validator.$invalid;
    },

    isRequired() {
      return this.hasValidator && this.validator.required !== undefined;
    },
    isValidatorRequired() {
      return this.isRequired && this.validator.required.$invalid && this.alreadySubmitted;
    },

    isMinLength() {
      return this.hasValidator && this.validator.minLength !== undefined;
    },
    isValidatorMinLength() {
      return this.isMinLength && this.validator.minLength.$invalid && this.alreadySubmitted;
    },
    isMaxLength() {
      return this.hasValidator && this.validator.maxLength !== undefined;
    },
    isValidatorMaxLength() {
      return this.isMaxLength && this.validator.maxLength.$invalid && this.alreadySubmitted;
    },
    style() {
      return this.label === '' || this.label === undefined ? 'display: none;' : '';
    },
  }
}
</script>

<template>
  <div :class="this.className" class="form-group">
    <AkLabel :required="isRequired" :style="this.style">{{ this.label }}</AkLabel>
    <Textarea 
         v-model="currentValue"
         ref="textarea"
        :class="{'p-invalid':isInvalid && alreadySubmitted}" 
        :disabled="isDisabled" 
        :readonly="isReadonly"
        :style="isReadonly ? 'cursor: default;' : ''"
        :placeholder="getPlaceholder"
        :type="inputType"
        :rows="rows"
        class="form-control"/>
    <small
        v-if="isValidatorRequired"
        class="p-error">{{
        $t("field_required", [this.label])
      }}</small>

    <small
        v-if="isValidatorMinLength"
        class="p-error">{{ $t("min_length", [this.label, this.validator.minLength.$params.min]) }}</small>

    <small
        v-if="isValidatorMaxLength"
        class="p-error">{{ $t("max_length", [this.label, this.validator.maxLength.$params.max]) }}</small>
  </div>
</template>