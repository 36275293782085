<script>
import useVuelidate from '@vuelidate/core'
import { required, maxLength } from '@vuelidate/validators';

/* COMPOSANTS */
import AkFormList from "@components/layout/AkFormList";
import AkInputText from "@components/input/AkInputText";
import AkDateTime from "@components/input/AkDateTime";
import AkDialog from "@components/general/AkDialog";
import AkTinyMCE from "@components/input/AkTinyMCE";

/* MIXINS */
import randomRef from "@mixins/randomRef";
import utilsMixin from "@mixins/utilsMixin";
import roleMixin from "@mixins/roleMixin";

/* SERVICES */
import actualiteService from "@services/actualiteService";
import rolePermissionService from "@services/rolePermissionService";

export default {
  components: {AkFormList, AkInputText, AkTinyMCE, AkDateTime, AkDialog}, 
  mixins: [randomRef, utilsMixin, roleMixin],
  metaInfo() {
    return {
      title: "actualite.list",
    }
  },
  setup() {
    return {v$: useVuelidate()}
  },
  data() {
    return {
      list: [],
      current: {},
      loading: false,
    }
  },
  validations() {
    return {
      current: {
        title: {required,  maxLength: maxLength(500)},
        content: {required,  maxLength: maxLength(5000)},
        dateStart: {required},
        dateEnd: {},
      }
    }
  },
  mounted() {
    this.getRef().showTotalLoader();
  
    let p0 = rolePermissionService.authorizeAccessByPermission(this.permissionConst.actualite.manage);
  
    let p1 = actualiteService.findAll({});
    p1.then(data => this.list = data);
  
    Promise.all([p0, p1]).then(() => {
      this.loading = false;
      this.getRef().hideLoader();
    });
  },
  methods: {
    create() {
      this.getRef().resetMessages();
      this.v$.$touch();
      if (this.v$.$error) return;

      let start = this.current.dateStart;
      let end = this.current.dateEnd;
      if(start && end) {
        if(new Date(start) > new Date(end)) {
           this.$refs.dialogCreate.error("La date de fin doit être après la date de début");
           return;
        }
      }

      this.getRef().showTotalLoader();
      actualiteService.create(this.current).then((data) => {
        console.log("Create response")
        console.log(data)
        this.list.push(data);
        this.current = {};
        
        this.getRef().success(this.$t("actualite.added"));
        this.$refs.dialogCreate.hide();
      }).finally(() => {
        this.getRef().hideLoader();
      });
    },
    update() {
      this.getRef().resetMessages();
      this.v$.$touch();
      if (this.v$.$error) return;
      
      let start = this.current.dateStart;
      let end = this.current.dateEnd;
      if(start && end) {
        if(new Date(start) > new Date(end)) {
           this.$refs.dialogUpdate.error("La date de fin doit être après la date de début");
           return;
        }
      }
      
      this.getRef().showTotalLoader();
      actualiteService.update(this.current).then(data => {
        this.replaceInListById(this.list, data);
        this.current = {};
        
        this.getRef().success(this.$t("actualite.updated"));
        this.$refs.dialogUpdate.hide();
      }).finally(() => {
        this.getRef().hideLoader();
      });
    },
    
    delete() {
      this.getRef().resetMessages();
      actualiteService.delete(this.current).then(data => {
        this.removeInListById(this.list, data.id);
        this.getRef().success(this.$t("actualite.deleted"));
      }).catch(e => {
        this.getRef().error(e.response.data.error);
      });
    },

    openCreateDialog() {
      this.current = {};
      this.$refs.dialogCreate.show();
    },
    openEditDialog(data) {
      this.current = {...data};
      this.$refs.dialogUpdate.show();
    },
    openDeleteDialog(data) {
      this.current = data;
      this.$refs.dialogDelete.show();
    },
  },
}
</script>

<template v-if="!roleLoading && this.canViewAdmin()">
  <AkFormList :ref="ref" :title="$t('actualite.list')">
    <template v-slot:action>
      <button v-if=this.canEditAdmin() class="btn btn-inverse-primary float-right" @click="openCreateDialog()">
        <i class="fe fe-plus pr-1"/>
        {{ $t('add') }}
      </button>
    </template>
    <template v-slot:list>
      <div class="row">
        <div class="col-lg-12">
          <div class="card card-statistics">
            <div class="card-body">
              <div class="table-responsive">
                <DataTable 
                   :loading="loading" 
                   :paginator="false"
                   :value="list"
                   class="table"
                   removableSort
                   responsiveLayout="scroll"
                   stripedRows>
                  <template #empty>
                    {{ $t("list_empty") }}
                  </template>
                  <Column field="label" :header="$t('actualite.title')" :sortable="true">
                    <template #body="slotProps">
                      {{ slotProps.data.title }}
                    </template>
                  </Column>
                  <Column field="label" :header="$t('actualite.creator')" :sortable="true">
                    <template #body="slotProps">
                      {{ slotProps.data.creatorName }}
                    </template>
                  </Column>
                  <Column field="label" :header="$t('actualite.dateStart')" :sortable="true">
                    <template #body="slotProps">
                      {{ displayDate(slotProps.data.dateStart) }}
                    </template>
                  </Column>
                  <Column field="label" :header="$t('actualite.dateEnd')" :sortable="true">
                    <template #body="slotProps">
                      {{ displayDate(slotProps.data.dateEnd) }}
                    </template>
                  </Column>
                  <Column bodyStyle="text-align: right; overflow: visible"
                          headerStyle=" width: 160px; text-align: center">
                    <template #body="slotProps">
                      <span v-if=this.canEditAdmin() 
                        class="btn btn-xs btn-inverse-primary pointer"
                        @click="openEditDialog(slotProps.data)">
                        <i class="fe fe-edit"/></span>
                      <span v-if=this.canDeleteAdmin() 
                        class="btn btn-xs btn-inverse-danger ml-1"
                        @click="openDeleteDialog(slotProps.data)">
                        <i class="fe fe-trash"/></span>
                    </template>
                  </Column>
                </DataTable>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>

    <template v-slot:extra>
      <AkDialog ref="dialogCreate"
        :title="$t('actualite.create_dialog')"
        :validate-label="$t('add')"
        width="1000px"
        @validate="this.create()">
        <div class="form-row">
          <AkInputText :label="$t('actualite.title')"
              v-model="current.title"
              :validator="v$.current.title" 
              class-name="col-12"/>
        </div>
        <div class="form-row">
          <AkDateTime v-model="current.dateStart"
                      :label="$t('actualite.dateStart')"
                      :showTime=false
                      :validator="v$.current.dateStart"
                      class-name="col-md-6"/>
          <AkDateTime v-model="current.dateEnd"
                      :label="$t('actualite.dateEnd')"
                      :showTime=false
                      :validator="v$.current.dateEnd"
                      class-name="col-md-6"/>
        </div>
        <div class="form-row">
          <AkTinyMCE :label="$t('actualite.content')"
                       :rows="18"
                       v-model="current.content"
                       :validator="v$.current.content"
                       class-name="col-12"/>
        </div>
      </AkDialog>
      
      <AkDialog ref="dialogUpdate"
        :title="$t('actualite.edit_dialog')"
        :validate-label="$t('update')"
        width="1000px"
        @validate="this.update()">
        <div class="form-row">
          <AkInputText :label="$t('actualite.title')"
              v-model="current.title"
              :validator="v$.current.title" 
              class-name="col-12"/>
        </div>
        <div class="form-row">
          <AkDateTime v-model="current.dateStart"
                      :label="$t('actualite.dateStart')"
                      :showTime=false
                      :validator="v$.current.dateStart"
                      class-name="col-md-6"/>
          <AkDateTime v-model="current.dateEnd"
                      :label="$t('actualite.dateEnd')"
                      :showTime=false
                      :validator="v$.current.dateEnd"
                      class-name="col-md-6"/>
        </div>
        <div class="form-row">
          <AkTinyMCE :label="$t('actualite.content')"
                       :rows="18"
                       v-model="current.content"
                       :validator="v$.current.content"
                       class-name="col-12"/>
        </div>
      </AkDialog>
      
      <AkDialog ref="dialogDelete"
        :auto-hide-on-validate="true"
        :cancel-label="$t('no')"
        :title="$t('actualite.delete_dialog')"
        :validate-label="$t('yes')"
        width="450px"
        @validate="this.delete()">
        <div class="confirmation-content">
          <i class="fe fe-alert-triangle mr-1" style="font-size: 2rem" />
          <span >{{ $t('actualite.confirm_delete') }}</span>
        </div>
      </AkDialog>
    </template>
  </AkFormList>
</template>